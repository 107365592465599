@import "../../../__variables.scss";

#solutions-dome {
  position: relative;
  background-color: $white;
  padding: 30px 0px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $torquoise;
    margin-bottom: 20px;
    z-index: 1;
    position: relative;
  }

  .wrapper {
    text-align: center;
    margin: 5vh 0px;
    img {
      width: 35vw;
    }
  }

  .text-split {
    display: flex;
    .text-section {
      width: 50%;
      padding: 0px 50px;
      z-index: 1;
    position: relative;
      .text {
        color: $torquoise;
        margin-bottom: 30px;
        &.sub {
          font-size: $text-large;
        }
        &.bold {
          font-family: 'Stolzl-Bold';
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #solutions-dome {
    .headline {
      font-size: 1.75em;
    }
    .text-split {
      display: block;
      .text-section {
        width: 100%;
        padding: 0px;
      }
    }
    .wrapper {
      img {
        width: 80%;
      }
    }
  }
}
