@import "../../__variables.scss";

#header {
  &.user-scrolled {
    background: rgb(93, 181, 181);
    background: linear-gradient(
      180deg,
      rgba(93, 181, 181, 1) 0%,
      rgba(115, 166, 173, 0.005) 100%
    );
  }

  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
  height: 130px;

  transition: all 0.2s ease-out;
  display: flex;
  // align-items: center;

  .logo {
    cursor: pointer;
    font-weight: bolder;
    color: $white;
    margin-left: 20px;
    margin-top: 10px;
    img {
      max-height: 110px;
    }
  }

  .navigation-wrapper {
    position: absolute;
    right: 0px;
    top: 30px;
    right: 30px;
  }

  @media (max-width: $breakpoint) {
    .logo {
      img {
        max-height: 55px;
      }
    }
    .navigation-wrapper {
      position: absolute;
      right: 0px;
      top: 20px;
      right: 0px;
    }
  }
}
