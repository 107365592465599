@import "../../__variables.scss";

#references {

  .line {
    pointer-events: none;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    z-index: 0;
    &.one {
      background-image: url('../../assets/image/references/Line_01_3.png');
      height: 140vh;
      top: 0;
    }
  }
  
  @media (max-width: $breakpoint) {
      
  }
}
