@import '../../../__variables.scss';

#home-map {
  position: relative;
  background-color: $white;
  padding: 30px 0px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $torquoise;
  }

  .wrapper {
    text-align: center;
    margin: 7.5vh 0px;
    position: relative;
    z-index: 1;
    img {
      width: 35vw;
    }
  }

  .question {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 2em;
    color: $torquoise;
    position: relative;
    z-index: 1;
  }

  .cta-wrapper {
    text-align: center;
    .cta {
      position: relative;
      z-index: 1;
      display: inline-block;
      background-color: $torquoise;
      color: $white;
      text-transform: uppercase;
      border-radius: 15px;
      padding: 10px;
      width: 30vw;
      font-size: $text-large;
      font-family: 'Stolzl-Thin', 'sans-serif';
      text-decoration: none;
      font-family: 'Stolzl-Bold';
    }
  }

  @media (max-width: $breakpoint) {
    .wrapper {
      img {
        width: 80%;
      }
    }

    .cta-wrapper {
      text-align: center;
      .cta {
        width: 80%;
      }
    }
  }
}
