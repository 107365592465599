@import "../../../__variables.scss";

#home-percent {
  position: relative;
  background-color: $torquoise;
  padding: 30px 0px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
  }

  .wrapper {
    text-align: center;
    margin-top: 30px;
    img {
      width: 80vw;
    }
  }

  .text-wrapper {
    margin-left: auto;
    margin-right: auto;
    bottom: 80px;
    width: 100%;
    text-align: center;
    font-family: 'Stolzl-Bold';
    display: inline-block;
    margin-left: 10vw;
    margin-top: 20vh;
    .text {
      text-align: left;
      font-family: "Stolzl-Medium", sans-serif;
      font-size: 4em;
      color: $white;
      text-transform: uppercase;
    }
  }
  
  @media (max-width: $breakpoint) {
    .headline {
      font-size: 1.65em;
    }
    .wrapper {
      margin: 25px 0px;
      img {
        width: 90%;
      }
    }
    .text-wrapper {
      width: auto;
      padding: 0px 15px;
      margin: 0px;
      .text {
        font-size: 1.75em;
      }
    }
  }
}
