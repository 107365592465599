@import "../../../__variables.scss";

#photovoltaic-requirements {
  background-color: $white;
  padding: 100px 0px;
  text-align: center;
  padding-bottom: 300px;
  .subheadline {
    font-size: $text-large;
    color: $torquoise;
    z-index: 1;
    position: relative;
  }
  .headline {
    position: relative;
    font-family: "Stolzl-Medium";
    text-transform: uppercase;
    color: $blue;
    font-size: 40px;
    word-wrap: break-word;
    z-index: 1;
  }
  .text {
    color: $blue;
    font-family: "Stolzl-Book";
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    z-index: 1;
    position: relative;
  }
  .items {
    // background-image: url("../../../assets/image/photovoltaic/arrows.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    gap: 50px;
    .item {
      z-index: 1;
      width: 250px;
      padding: 15px;
      color: $white;
      background-color: $torquoise;
      font-size: $text-medium;
      font-family: "Stolzl-Book";
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: $breakpoint) {
    padding-bottom: 100px;
    .text {
      width: 100%;
    }
    .items {
      background-image: none;
      display: block;
      margin-top: 0px;
      .item {
        width: calc(100% - 30px);
        margin: 15px;
      }
    }
  }
}
