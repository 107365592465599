@import "../../../../__variables.scss";

.references-overview-section {
  margin-bottom: 100px;

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 100px transparent;
    border-left: solid 50vw $white;
    border-right: solid 50vw $white;
  }

  .headline {
    position: relative;
    z-index: 1;
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
  }

  .bulletpoints-wrapper {
    text-align: center;
    .bulletpoints {
      text-align: left;
      display: inline-block;
      margin: 50px 0px;
      .item {
        text-transform: uppercase;
        color: $white;
        font-size: $text-large;
        img {
          height: 50px;
          margin-right: 10px;
        }
      }
    }
  }

  .slider-wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    .styled-slider-item {
      border-radius: 30px;
      border: 4px solid $orange;
      padding: 20px;
      .text, .author {
        color: $white;
      }
      .author {
          margin: 10px 0px;
          font-family: 'Stolzl-Bold';
      }
    }
  }

  @media (max-width: $breakpoint) {
  }
}
