@import '../../../../__variables.scss';

.google-map {
  margin-bottom: 30px;
  .google-maps-marker {
    text-align: center;
    position: relative;
    .icon-wrapper {
      img {
        height: 50px;
        width: 35px;
        position: relative;
        top: -50px;
        left: -17.5px;
      }
    }
    .overlay {
      z-index: 99999;
      position: absolute;
      width: 1000px;
      text-align: left;
      .item {
        border-radius: $default-border-radius;
        padding: 5px;
        background-color: $white;
        display: inline-block;
        width: 250px;
        margin: 5px;
        // text-align: center;
        .prop {
          .key,
          .value {
            display: inline-block;
          }
          .key {
            font-weight: 'Stolzl-Bold';
            margin-right: 10px;
          }
          .value {
            font-weight: thin;
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint) {
  }
}
