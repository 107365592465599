@import "../../__variables.scss";

#dataProtection {
  .text {
    padding: 100px 0px;
    background-color: $torquoise;
    p,
    strong,
    a,
    li, div {
      color: $white;

      text-decoration: none;
    }
  }
}
@media (max-width: $breakpoint) {
  #dataProtection {
    .text {
      a {
        word-break: break-all;
      }
    }
  }
}
