@import '../../../_variables';

#photovoltaic-advantages {
  background-color: $white;
  padding: 100px 0px;
  position: relative;
  text-align: center;
  // padding-top: 600px;
  .headline {
    font-family: 'Stolzl-Medium';
    text-transform: uppercase;
    color: $blue;
    font-size: 40px;
    word-wrap: break-word;
    z-index: 1;
    position: relative;
  }
  .subheadline {
    z-index: 1;
    position: relative;
    font-size: $text-large;
    color: $torquoise;
  }
  .items {
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 75px;
    .item {
      z-index: 1;
      position: relative;
      flex: 1 1 0px;
      min-width: 30%;
      text-align: center;
      display: flex;
      gap: 25px;
      justify-content: center;
      flex-wrap: wrap;
      align-items: baseline;
      max-width: 25%;
      .image {
        width: 100%;
        img {
          height: 50px;
        }
      }
      .text {
        max-width: 200px;
        text-align: center;
        font-family: 'Stolzl-Book';
        color: $blue;
      }
    }
  }
  @media (max-width: $breakpoint) {
    padding-top: 100px;
    .items {
      gap: 25px;
      .item {
        min-width: 40%;
        gap: 15px;
      }
    }
  }
}
