@import "../../__variables.scss";

#imprint {
  .text {
    padding: 100px 0px;
    background-color: $torquoise;
    p,
    strong {
      color: $white;
    }
  }
}
@media (max-width: $breakpoint) {
}
