@import "../../../__variables.scss";

#navigation {
  .item {
    text-decoration: none;
    padding: 3px 15px;
    display: inline-block;
    color: $white;
    font-size: $text-small;
    margin: 0px 10px;
    border: thin solid $orange;
    border-radius: 50px;
    text-transform: uppercase;
    &.selected {
      border-color: $white;
    }
  }

  .mobile-toggle {
    display: none;
    line-height: 100%;
    img {
      height: 30px;
    }
  }

  @media (max-width: $breakpoint) {
    .item {
      border: none;
    }
    .item-list {
      z-index: 50;
      display: none;
      position: fixed;
      left: 0px;
      top: 80px;
      width: 100%;
      background-color: $white;
      box-shadow: none;
      .item {
        display: block;
        color: $black;
        padding: 7.5px;
        border-bottom: thin solid $lightgray;
        border-radius: 0px;
      }
      &.show {
        display: block;
      }
    }
    .mobile-toggle {
      display: block;
    }
  }
}
