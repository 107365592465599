@import '../../../__variables.scss';

#photovoltaic-numberation {
  padding: 100px 0px;
  padding-bottom: 200px;
  background-color: $lightgray;
  position: relative;
  // &:after {
  //   position: absolute;
  //   bottom: 0px;
  //   left: 0;
  //   content: "";
  //   width: 0;
  //   height: 0;
  //   border-top: solid 100px transparent;
  //   border-left: solid 50vw $torquoise;
  //   border-right: solid 50vw $torquoise;
  // }
  .subheadline {
    text-align: center;
    font-size: $text-large;
    color: $torquoise;
    z-index: 1;
    position: relative;
  }
  .headline {
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 40px;
    color: $blue;
    text-transform: uppercase;
    font-family: 'Stolzl-Medium';
    margin-bottom: 50px;
  }
  .item {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 25px;
    margin-left: 40%;
    z-index: 1;
    .image {
      z-index: 1;
      img {
        width: 50px;
      }
    }
    .text {
      z-index: 1;
      font-size: $text-medium;
      font-family: 'Stolzl-Book';
      color: $blue;
    }
  }
  @media (max-width: $breakpoint) {
    .item {
      margin-left: 0px;
    }
  }
}
