@import "../../../__variables.scss";

.park-modal-wrapper {
  .cta {
    border-radius: 15px;
    text-align: center;
    background-color: white;
    cursor: pointer;
    .image {
        width: 100%;
        height: 100px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .name {
        padding: 10px 0px;
    }
  }
}

.park-modal {
  .download-cta-wrapper {
    text-align: center;
    margin-bottom: 15px;
    .download {
      padding: 10px;
      display: inline-block;
      background-color: $orange;
      color: $white;
      font-size: $text-large;
      font-family: "Stolzl-Bold", "sans-serif";
      border-radius: 15px;
      text-decoration: none;
    }
  }
  .meta {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: thin solid $torquoise;
    margin-bottom: 10px;
  }

  .close-cta-wrapper {
    text-align: center;
    margin-bottom: 15px;
    .close {
      padding: 5px 10px;
      cursor: pointer;
      display: inline-block;
      background-color: $orange;
      color: $white;
      font-size: $text-large;
      border-radius: 15px;
      text-decoration: none;
    }
  }

  .download-link {
    text-decoration: none;
    color: #4B4B4D;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
