@import '../../__variables.scss';

#contact {
  .line {
    pointer-events: none;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    z-index: 0;
    &.one {
      background-image: url('../../assets/image/contact/Line_01_5.png');
      height: 130vh;
      top: 0;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
