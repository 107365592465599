@import "../../../__variables.scss";

#contact-plugin {
  position: relative;
  background-color: $torquoise;
  padding-bottom: 100px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
    padding-top: 30px;
  }

  .placeholder-wrapper {
    text-align: center;
    position: relative;
    z-index: 1;
    .placeholder {
      color: $torquoise;
      background-color: $white;
      padding: 150px;
      margin: 100px 0px;
    }
  }

  .text {
    position: relative;
    z-index: 1;
    p, a {
      text-align: center;
      color: $white;
      text-decoration: none;
      &.highlight {
        font-family: 'Stolzl-Bold';
        font-size: $text-large;
      }
    }
  }

  @media (max-width: $breakpoint) {
  }
}
