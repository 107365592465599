@import "../../../__variables.scss";

#photovoltaic-construction {
  background-color: $white;
  padding: 100px 0px;
  position: relative;
  text-align: center;
  .headline {
    font-family: "Stolzl-Medium";
    text-transform: uppercase;
    color: $blue;
    font-size: 40px;
    word-wrap: break-word;
  }
  .subheadline {
    font-size: $text-large;
    color: $torquoise;
  }
  .items {
    display: flex;
    gap: 25px;
    margin-top: 75px;
    justify-content: center;
    .item {
      z-index: 1;
      padding: 25px;
      min-width: 25%;
      flex: 1 1 0px;
      background-color: $torquoise;
      flex-grow: 0;
      .image {
        text-align: center;
        img {
          height: 125px;
        }
      }
      .headline {
        margin-top: 15px;
        font-family: "Stolzl-Medium";
        text-transform: uppercase;
        font-size: $text-large;
        color: $white;
      }
      .text {
        text-align: left;
        font-size: $text-medium;
        color: $white;
        margin-top: 15px;
        font-family: "Stolzl-Book";
      }
    }
  }
  @media (max-width: $breakpoint) {
    .items {
      display: block;
      .item {
        margin-top: 25px;
      }
    }
  }
}
