@import "../../../__variables.scss";

#photovoltaic-overview {
  position: relative;
  background-color: $white;
  padding: 100px 0px;
  display: flex;
  gap: 50px;
  align-items: center;
  .image,
  .content {
    flex: 1 1 0px;
  }
  .image {
    img {
      max-width: 100%;
    }
  }

  .content {
    .subheadline {
      font-size: $text-large;
      color: $torquoise;
    }
    .headline {
      font-size: 35px;
      font-family: 'Stolzl-Bold';
      color: $blue;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
    .text {
      .headline {
        font-size: $text-large;
        font-family: 'Stolzl-Bold';
        margin-bottom: 0px;
        margin-top: 25px;
      }
      .text {
        font-size: $text-large;
        font-family: "Stolzl-Book";
        color: $blue;
      }
    }
  }

  @media (max-width: $breakpoint) {
    display: block;
    .image {
      margin-bottom: 25px;
    }
  }
}
