@import "../../__variables.scss";

#participation {
  background-color: $torquoise;
  .soll-ist-vergleich {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 50px 0px;
    border-bottom: thin solid $white;
    .cta {
      display: inline-block;
      padding: 25px;
      text-decoration: none;
      background-color: $orange;
      border-radius: 15px;
      .download,
      .label {
        color: $white;
        font-size: $text-x-large;
        font-family: "Stolzl-Bold", "sans-serif";
      }
      .label {
        font-size: $text-small;
        font-family: "Stolzl-Thin";

      }
    }
  }

  .park-list {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-left: auto;
    padding: 50px;
    .park-modal-wrapper {
      width: calc(33% - 3.5px);
    }
  }

  .line {
    pointer-events: none;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    z-index: 0;
    &.one {
      background-image: url("../../assets/image/start/line_1.png");
      height: 150vh;
      top: 0;
    }
  }

  @media (max-width: $breakpoint) {
    .park-list {
      position: relative;
      z-index: 1;
      display: block;
      padding: 0px;
      .park-modal-wrapper {
        width: 100%;
        margin: 10px 0px;
      }
    }
  }
}
