@import "../../../__variables.scss";

#solutions-intro {
  position: relative;
  height: 90vh;
  background-image: url("../../../assets/image/solutions/HEADER.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 100px transparent;
    border-left: solid 50vw $torquoise;
    border-right: solid 50vw $torquoise;
  }

  .text-wrapper {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    z-index: 1;
    bottom: 10vh;
    width: 100%;
    text-align: center;
    font-family: 'Stolzl-Bold';
    .center-wrapper {
      display: inline-block;
      .text {
        text-align: left;
        font-family: "Stolzl-Medium", sans-serif;
        font-size: 5em;
        color: $white;
        text-transform: uppercase;
      }
    }
  }

  .scroll-down {
    background-image: url("../../../assets/image/scroll_down.svg");
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 15px;
    z-index: 1;
  }
}

@media (max-width: $breakpoint) {
  #solutions-intro {
    .text-wrapper {
      .center-wrapper {
        .text {
          font-size: 2em;
          text-align: center;
        }
      }
    }
  }
}
