@import '../../__variables.scss';

#footer {
  background-color: $white;
  padding: 30px 0px;

  .info {
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
    .container {
      display: flex;
      align-items: center;
      .block {
        &:last-child {
          text-align: right;
        }
        width: 50%;
        flex: 1 1 0px;
        &.social {
          .icon {
            display: inline-block;
            margin-right: 10px;
            img {
              height: 35px;
            }
          }
        }
        &.legal {
          text-transform: uppercase;
          * {
            color: $torquoise;
            text-decoration: none;
            font-size: $text-small;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    div {
      color: $torquoise;
      font-size: $text-small;
    }
  }

  @media (max-width: $breakpoint) {
    .info {
      padding-bottom: 0px;
      .container {
        display: block;
        .block {
          text-align: center;
          &:last-child {
            text-align: center;
            margin-top: 30px;
          }
          width: 100%;
          &.social {
            .icon {
              display: inline-block;
              margin-right: 10px;
              img {
                height: 35px;
              }
            }
          }
          &.legal {
            text-transform: uppercase;
            * {
              color: $torquoise;
              text-decoration: none;
              font-size: $text-small;
            }
          }
        }
      }
    }
  }
}
