@import '../../__variables.scss';

#home {
  .line {
    pointer-events: none;
    background-size: 100vw auto;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    z-index: 0;
    &.one {
      background-image: url('../../assets/image/start/line_1.png');
      height: 150vh;
      top: 0;
    }
    &.two {
      background-image: url('../../assets/image/start/line_2.png');
      height: 250vh;
      top: 150vh;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
