@import '../../../__variables.scss';

.gallery-collection {
  padding-bottom: 15px;
  border-bottom: thin solid $white;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  .name {
    font-size: 30px;
    margin-bottom: 10px;
    color: $white;
    font-family: 'Stolzl-Bold', serif;
  }
  .data-split {
    display: flex;
    gap: 25px;
    .videos {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .video {
        width: calc(50% - 5px);
        video {
          max-width: 100%;
        }
      }
    }

    .images {
      .carousel.carousel-slider .control-arrow {
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .google-maps-embed {
      &.split {
        max-width: 40%;
      }
    }
    .images,
    .videos,
    .google-maps-embed {
      flex: 1 1 0px;
    }
  }

  @media (max-width: $breakpoint) {
    .data-split {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      .videos {
        display: block;
        .video {
          width: 100%;
        }
      }
      .images {
        .slider-image {
          margin-bottom: 40px;
        }
      }
      .google-maps-embed {
        &.split {
          max-width: 100%;
        }
      }
      .images,
      .videos,
      .google-maps-embed {
        flex: auto;
      }
    }
  }
}
