@import '../../__variables.scss';

#photovoltaic {
  .separator {
    padding: 10px;
    background-color: $torquoise;
    font-size: $text-x-large;
    font-family: 'Stolzl-Medium';
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }
  .line {
    pointer-events: none;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100vw;
    position: absolute;
    z-index: 0;
    &.one {
      background-image: url('../../assets/image/photovoltaic/Linie_1.png');
      height: 200vh;
      top: 0;
    }
    &.two {
      background-image: url('../../assets/image/photovoltaic/Linie_2.png');
      height: 140vh;
      top: 250vh;
    }
    &.three {
      background-image: url('../../assets/image/photovoltaic/Linie_3.png');
      height: 140vh;
      top: 450vh;
      display: none;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
