@import "../../../__variables.scss";

#home-process {
  position: relative;
  background-color: $white;
  background-image: url("../../../assets/image/start/process.png");
  background-position: top right;
  background-size: 100% auto;
  background-repeat: no-repeat;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $torquoise;
    padding-top: 30px;
    position: relative;
    z-index: 1;
  }

  .bulletpoints {
    width: 60%;
    margin-left: 5%;
    margin-top: 10vh;
    margin-bottom: 5vh;
    z-index: 1;
    position: relative;
    .point {
      display: flex;
      padding: 10px;
      align-items: center;
      background-color: $torquoise;
      margin-bottom: 5px;
      border-radius: 15px;
      &.equal {
        background-color: $orange;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .index {
        width: 15%;
        font-size: 4em;
        font-family: 'Stolzl-Thin', 'sans-serif';
        font-family: 'Stolzl-Bold';
        color: $white;
        text-align: center;
      }
      .content {
        width: 85%;
        padding-left: 15px;
        border-left: 2px solid $white;
        .subheadline {
          font-size: $text-medium;
          font-family: 'Stolzl-Bold';
          text-align: left;
          color: $white;
          font-family: 'Stolzl-Bold', 'sans-serif';
        }
        .text {
          font-size: $text-medium;
          color: $white;
        }
      }
    }
  }

  @media (max-width: $breakpoint) {
    margin-top: 50px;
    background-image: none;
    .headline {
      font-size: 1.75em;
    }

    .bulletpoints {
      width: 100%;
      margin: 0px;
      padding: 15px;
    }
  }
}
