@import '../../../__variables.scss';

#aboutus-main {
  position: relative;
  background-color: $torquoise;
  padding-bottom: 100px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
    padding-top: 30px;
  }

  .headline {
    font-size: 2.5em;
    margin-bottom: 50px;
  }

  .text-split {
    display: flex;
    position: relative;
    z-index: 1;
    .column {
      width: 50%;
      padding: 50px;
      .text {
        margin-bottom: 50px;
        .headline {
          font-size: $text-medium;
          font-family: 'Stolzl-Bold';
          text-transform: uppercase;
          text-align: left;
        }
        .content {
          text-align: left;
          color: $white;
        }
        .cta-wrapper {
          text-align: center;
          margin-top: 20px;
          .cta {
            cursor: pointer;
            background-color: $orange;
            color: $white;
            font-size: $text-medium;
            text-transform: uppercase;
            padding: 5px 10px;
            display: inline-block;
            border-radius: 10px;
            font-family: 'Stolzl-Thin';
          }
        }
      }
    }
  }

  .person-list {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    .person {
      flex: 1 1 0px;
      padding: 20px;
      border-radius: 40px;
      border: 3px solid $orange;
      margin-bottom: 100px;
      position: relative;
      z-index: 1;
      background-color: $torquoise;
      .image-content-split {
        text-align: center;
        .image {
          img {
            border-radius: 20px;
            max-width: 200px;
          }
        }
        .content {
          text-align: center;
          p {
            margin: 0px;
            color: $white;
            font-size: $text-small;
            &.name {
              font-family: 'Stolzl-Bold';
              font-size: $text-large;
              text-transform: uppercase;
              // margin-top: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $breakpoint) {
  #aboutus-main {
    .headline {
      font-size: 1.75em;
    }
    .person-list {
      display: block;
      .person {
        margin-bottom: 50px;
        .image-content-split {
          display: block;
          .image {
            width: 100%;
            img {
              border-radius: 20px;
              max-width: 100%;
            }
          }
          .content {
            margin-top: 15px;
            width: 100%;
            padding-left: 0;
            p {
              color: $white;
              font-size: $text-small;
              &.name {
                font-family: 'Stolzl-Bold';
                font-size: $text-medium;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
