@import './__variables';

@font-face {
  font-family: 'Stolzl-Bold';
  src: local('Stolzl-Bold'), url(./assets/font/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Book';
  src: local('Stolzl-Book'), url(./assets/font/Stolzl-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Light';
  src: local('Stolzl-Light'), url(./assets/font/Stolzl-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Medium';
  src: local('Stolzl-Medium'), url(./assets/font/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Regular';
  src: local('Stolzl-Regular'), url(./assets/font/Stolzl-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Stolzl-Thin';
  src: local('Stolzl-Thin'), url(./assets/font/Stolzl-Thin.ttf) format('truetype');
}

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Stolzl-Regular', sans-serif;
  font-size: $text-medium;
  color: $text;
}

.button {
  border-radius: $default-border-radius;
  background-color: $bg-button;
  color: $text-button;
  display: inline-block;
  padding: 5px 10px;
  font-family: 'intro-bold';
  cursor: pointer;
  border: none;
}

input {
  outline: none;
  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: thin solid $black !important;
  }
}
