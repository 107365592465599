@import "../../../__variables.scss";

#solutions-process {
  position: relative;
  background-color: $torquoise;
  padding-bottom: 100px;

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
    padding-top: 30px;
  }

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 100px transparent;
    border-left: solid 50vw $white;
    border-right: solid 50vw $white;
  }

  .bulletpoints {
    margin-left: 5%;
    padding-top: 10vh;
    margin-bottom: 5vh;
    z-index: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .point {
      width: 50%;
      padding: 10px;
      margin-bottom: 5px;
      margin-top: -50px;
      &.equal {
        margin-top: 100px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      .image {
        text-align: center;
        img {
          max-width: 100px;
        }
      }
      .subheadline {
        font-size: $text-medium;
        font-family: 'Stolzl-Bold';
        text-align: center;
        color: $white;
        font-family: "Stolzl-Bold", "sans-serif";
        margin: 20px 0px;
      }
      .text {
        font-size: $text-medium;
        color: $white;
        text-align: center;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: $breakpoint) {
    .bulletpoints {
      display: block;
      margin-left: 0;
      .point {
        width: 100%;
        margin-top: 20px;
      }
      .equal {
        margin: 0 !important;
      }
    }
  }
}
