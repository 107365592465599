@import "../../../__variables.scss";

#home-threetext {
  position: relative;
  height: 80vh;
  background-color: $torquoise;

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 100px transparent;
    border-left: solid 50vw $white;
    border-right: solid 50vw $white;
  }

  .headline {
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
    padding-top: 30px;
  }
  
  .block-wrapper {
    padding-top: 10vh;
    display: flex;
    .block {
      width: 33%;
      margin-top: 7vw;
      z-index: 1;
      .image {
        text-align: center;
        img {
          max-height: 100px;
        }
      }
      .headline {
        text-transform: uppercase;
        font-size: 2em;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .text {
        font-size: $text-medium;
        color: white;
        text-align: justify;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      &:first-child,
      &:last-child {
        margin-top: 5vh;
      }
    }
  }

  @media (max-width: $breakpoint) {
    height: auto;

    .block-wrapper {
      padding-top: 10vh;
      display: block;
      .block {
        width: 100%;
        padding-bottom: 50px;
        margin-top: 0px;
        &:last-child  {
          padding-bottom: 150px;
        }
        .headline {
          text-transform: uppercase;
          font-size: 2em;
          margin-top: 10px;
          margin-bottom: 20px;
        }
        .text {
          width: 100%;
          padding: 0px 15px;
        }
        &:first-child,
        &:last-child {
          margin-top: 5vh;
        }
      }
    }
  }
}
