@import "../../__variables.scss";

.carousel {
  &.carousel-slider {
    overflow: visible;
  }
  .control-dots {
      margin: 0px;
      bottom: -30px;
    .dot {
        box-shadow: none;
      }
  }
}
