@import "../../../__variables.scss";

#references-overview {
  position: relative;
  background-color: $torquoise;
  padding: 30px 0px;
  
  .headline {
    position: relative;
    z-index: 1;
    font-family: 'Stolzl-Bold';
    font-size: 4em;
    text-align: center;
    color: $white;
  }

  .wrapper {
    text-align: center;
    margin: 5vh 0px;
    img {
      width: 35vw;
    }
  }

  @media (max-width: $breakpoint) {
    .text-split {
      display: block;
      .text-section {
        width: 100%;
      }
    }
  }
}
