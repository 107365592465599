@import "../../../__variables.scss";

#references-intro {
  position: relative;
  height: 90vh;
  background-image: url("../../../assets/image/references/referenzen_bg_foto.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-top: solid 100px transparent;
    border-left: solid 50vw $torquoise;
    border-right: solid 50vw $torquoise;
  }

  .absolut-wrapper {
    position: absolute;
    width: 100%;
    z-index: 1;
    margin-top: 100px;
    .headline {
      text-align: center;
      font-family: 'Stolzl-Bold';
      font-family: "Stolzl-Medium", sans-serif;
      color: $white;
      font-size: 5em;
      text-transform: uppercase;
    }
  
    .buttetpoint-wrapper {
      .list {
        margin: 40px 0px;
        display: flex;
        .item {
          width: 33%;
          color: $white;
          text-align: center;
          font-size: $text-large;
          img {
            height: 10px;
            margin-right: 5px;
          }
        }
      }
      .hightlight {
        font-size: 2.5em;
        color: $white;
        text-align: center;
        img {
          width: 100px;
          margin-right: 20px;
        } 
      }
    }
  }

  .slider-wrapper {
    width: 75%;
    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
    .styled-slider-item {
      color: white;
      text-align: left;
    }
  }

  .scroll-down {
    background-image: url('../../../assets/image/scroll_down.svg');
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 15px;
    z-index: 1;
  }

  @media (max-width: $breakpoint) {
  }
}
